import React from 'react'
import ReactToolTip from 'react-tooltip'
import 'reflex-grid'
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`

  @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

  :root {
    --site-clr-light: #dcdcdc;
    --site-clr-dark: #192938;
    --site-clr-dark-alt: #111111;
    --site-clr-accent: #229897;
    --site-clr-dark-accent: #203A49;
    --site-clr-background: #070707;
    --site-clr-footer: #08141C;
  }

  html, body {
    height: 100%;
    width: 100%;
    margin: 0;
    background-color: var(--site-clr-background);
    scroll-behavior: smooth;
    overflow-x: hidden;
  }

  body {
    margin: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .reset-anchor, .reset-anchor:hover, .reset-anchor:visited, .reset-anchor:focus, .reset-anchor:active  {
    cursor: inherit;
    color: inherit;
    text-decoration: none;
    outline: 0;
  }

  .tool-tip-style {
    font-family: Roboto, Arial;
    background-color: var(--site-clr-accent) !important;
    &.place-bottom {
      &:after {
        border-bottom-color: var(--site-clr-accent) !important;
        border-bottom-style: solid !important;
        border-bottom-width: 6px !important;
      }
    }
    &.place-top {
      &:after {
        border-top-color: var(--site-clr-accent) !important;
        border-top-style: solid !important;
        border-top-width: 6px !important;
      }
    }
  }

  .fixed-width-container {
    margin: 0 auto;
    width: 100%;
  }

  @media screen and (min-width: 1600px) {
    .fixed-width-container {
      margin: 0 auto;
      width: clamp(80%, 90%, 2040px);
    }
  }
`

const Layout = ({children}) => {
  return (
    <React.Fragment>
      <GlobalStyle />
      <ReactToolTip className="tool-tip-style" delayHide={200} effect="solid" place="bottom" />
      {children}
    </React.Fragment>
  )
}

export default Layout 
